body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
  display: flex;
}
.header3-menu {
  flex: 1 1;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
  flex: 1 1;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-menu ul {
  display: flex;
  justify-content: end;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
    display: block;
  }
  .header3.home-page-wrapper .home-page .header3-menu {
    height: 0px;
  }
  .header3 .open .header3-menu {
    height: 192px !important;
  }
  .header3 .header3-menu {
    transition: height 0.3s ease;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu ul {
    display: block !important;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 28px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  margin-bottom: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content6-wrapper {
  min-height: 720px;
}
.content6-wrapper .content6 {
  height: 100%;
  display: flex;
  align-items: center;
}
.content6-wrapper .content6-text {
  min-height: 424px;
}
.content6-wrapper .content6-text > *.queue-anim-leaving {
  position: relative !important;
}
.content6-wrapper .content6-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
}
.content6-wrapper .content6-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
}
.content6-wrapper .content6-text ul {
  position: relative;
  display: inline-block;
}
.content6-wrapper .content6-text ul li {
  margin-bottom: 24px;
}
.content6-wrapper .content6-text ul li .content6-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content6-wrapper .content6-text ul li .content6-title,
.content6-wrapper .content6-text ul li .content6-content {
  margin-left: 45px;
}
.content6-wrapper .content6-text ul li .content6-title {
  font-size: 14px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    height: 860px;
    overflow: hidden;
  }
  .content6-wrapper .content6 {
    display: block;
  }
  .content6-wrapper .content6-img,
  .content6-wrapper .content6-text {
    display: block;
    width: 100%;
  }
  .content6-wrapper .content6-text > h1,
  .content6-wrapper .content6-text > p {
    text-align: center;
  }
  .content6-wrapper .content6-text > h1 {
    margin: 56px auto 16px;
  }
  .content6-wrapper .content6-text p {
    margin-bottom: 32px;
  }
  .content6-wrapper .content6-img {
    margin-top: 20px;
  }
}
.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #5f9bf1;
}
.feature6-title-bar {
  height: 6px;
  background: #5f9bf1;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #5f9bf1;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #5f9bf1;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(95, 155, 241, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #5f9bf1;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.content11-wrapper {
  height: 480px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 96px;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content11-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
  }
}
.teams2-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams2-wrapper .teams2 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams2-wrapper .teams2 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams2-wrapper .teams2 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
  min-height: 456px;
}
.teams2-wrapper .teams2 .block-wrapper .block {
  margin-bottom: 48px;
  vertical-align: text-top;
}
.teams2-wrapper .teams2 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams2-wrapper .teams2-image {
  color: #404040;
  width: 100%;
}
.teams2-wrapper .teams2-image img {
  width: 100%;
}
.teams2-wrapper .teams2-textWrapper {
  padding-left: 16px;
}
.teams2-wrapper .teams2-title {
  font-size: 18px;
  margin-bottom: 2px;
}
.teams2-wrapper .teams2-job {
  margin-bottom: 4px;
}
.teams2-wrapper .teams2-job,
.teams2-wrapper .teams2-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams2-wrapper {
    min-height: 1440px;
  }
  .teams2-wrapper .teams2.home-page {
    padding-bottom: 0;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
